@use "colors" as *;
@import "./node_modules/reset-css/sass/reset";

@font-face {
  font-family: 'paytype_regular';
  src: url('./fonts/Paytype-Rg.eot');
  src: url('./fonts/Paytype-Rg.eot?#iefix') format('embedded-opentype'), url('./fonts/Paytype-Rg.woff2') format('woff2'), url('./fonts/Paytype-Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'paytype_bold';
  src: url('./fonts/Paytype-Bd.eot');
  src: url('./fonts/Paytype-Bd.eot?#iefix') format('embedded-opentype'), url('./fonts/Paytype-Bd.woff2') format('woff2'), url('./fonts/Paytype-Bd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  overflow: hidden;
  font-family: var(--regular-font);
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  padding: 0;
  margin: 0;  
}

.button {
  background-color: var(--mp-blue);
  color: var(--white);
  position: relative;  
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  padding: 0 1.5625rem 2px;
  font-family: var(--bold-font);
  font-size: 18px;  
  line-height: 2.5rem;
  overflow: hidden;
  text-align: center;
  border-radius: 25px;
  min-width: 7.5rem;
  will-change: transform;
  transition: all .15s linear;
  text-decoration: none;
}

.button-secondary {
  background-color: transparent;
  color: var(--mp-blue);
  border: 2px solid var(--mp-blue);  
}

.button-link {
  background-color: transparent;
  color: var(--mp-blue);  
}

.infoDialog {
  position: absolute; 
  width:100%;
  top:0;
  background-color: var(--beige);
}

.infoContentWrapper {
  position:absolute; 
  top:50%; 
  height:16em; 
  margin-top:-8em; 
  width: 100%; 
  text-align: center;
}

.infoDialogText {
  padding-left: 32px; 
  padding-right: 32px;
  font-size: 16px;
  line-height: 20px;
}

.infoDialogCTA {  
  margin: 18px auto;  
}

.overlay {
  position: absolute;
  width:100%;
  height: 100%;
  top:0;
  background-color: var(--dark-overlay);
  opacity: 0;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.offscreen {
  position: absolute;
  top: -40000px;
} 

.hidden {
  display: none;
}